.header-wrapper {
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;

  &__bg {
    background-image: url(/img/lc2021/img/handlg.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    background-position: top right;
    width: 845px;
    width: 47vw;
    z-index: -9;
    top: 0;
    bottom: -7%;
    right: 0;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      background-image: url(/img/lc2021/img/mob/header_mob.svg);
      width: 75%;
    }

    @include media-breakpoint-only(sm) {
      width: 55%;
    }
    @include media-breakpoint-only(md) {
      width: 38%;
    }

    @media (min-width: 1700px) {
      width: 45vw;
    }

    @media (min-width: 1921px) {
      background-image: url(/img/lc2021/img/handxl.svg);
      top: 0;
      width: 845px;
    }
  }


  &__bg_without_vk {
    background-image: url(/img/lc2021/img/handlg_whats.svg);


    @media (min-width: 1921px) {
      background-image: url(/img/lc2021/img/handxl_whats.svg);
    }

    @include media-breakpoint-down(md) {
      background-image: url(/img/lc2021/img/mob/header_mob.svg);
    }

  }
}
