.button {
    background: $primary_color;
    color: $primary_text;
    font-size: 25px;
    font-weight: 600;
    border-radius: 50px;
    padding: .52em .8em;
    display: inline-block;
    text-align: center;
    transition: 0.1s ease;
    box-shadow: 0px 22px 32px -18px rgba(224, 91, 41, 0.53);
    outline: none !important;
        border: none;
    
    
    @include media-breakpoint-down(md) {
            font-size: 25px;
            padding: 20px 30px;
        }
    
    &:hover {
        text-decoration: none;
        color: $primary_text;
        box-shadow: none;
    }
    
    &_small {
        font-size: 18px;
    }
    
    &_without-shadow {
        box-shadow: none;
        
        &:hover {
            box-shadow: 0px 22px 32px -18px rgba(224, 91, 41, 0.53);
        }
    }

    &_base {
        background: $base_color;
        color: $accent_text;
        padding: 15px 50px;
        box-shadow: 0px 26px 32px -18px rgba(179, 55, 43, 0.26);

        &:hover {
            background: $base_color;
            color: $accent_text;
            box-shadow: none;

        }
        @include media-breakpoint-down(md) {
            font-size: 25px;
            padding: 20px 30px;
        }
    }


    
}