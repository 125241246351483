.teh-info {

    text-align: center;

    u {
      text-decoration-thickness: from-font;
    }

    &__img {
        max-height: 123px;
        max-width: 90px;

    }

    &__title {
        font-size: 32px;
        font-weight: 600;
        line-height: 35px;
        margin-bottom: 2.5em;
        margin-top: 20px;

        @media (min-width: 991px) and (max-width: 1199px){
            font-size: 28px;
        }
    }

    &__subtitle {
        font-size: 44px;
        font-weight: 600;
        line-height: 51px;
        margin-top: 1em;
        margin-bottom: 2em;

        @media (max-width: 768px) {
            font-size: 27px;
            line-height: 38px;
        }
    }

    .h_2 {
        margin-top: 2em;
    }
}
