.shiva {
  //    margin-bottom: 10em;


  @include media-breakpoint-down(md) {
    display: none;
  }

  &__img {
    display: block;
    margin: 0 auto;
  }

  &__title {
    font-size: 62px;
    text-align: center;
    font-weight: 600;
    line-height: 71px;
    margin-bottom: 104px;
    margin-top: 2.7em;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 35px;
    }
    @include media-breakpoint-only(lg) {
      font-size: 55px;
      line-height: 64px;
    }
  }

  &__prl {


    &_kafe {
      right: 15%;
      top: 87% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/cafe.svg);
        width: 141px;
        height: 110px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        right: 8%;
        top: 91% !important;
      }
    }


    &_auto {
      right: 7%;
      top: 68% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/auto.svg);
        width: 141px;
        height: 96px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        right: 1%;
        top: 72% !important;
      }
    }

    &_learn {
      right: 3%;
      top: 45% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/learn.svg);
        width: 141px;
        height: 98px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        right: -1%;
        top: 49% !important;
      }
    }

    &_turism {
      right: 8%;
      top: 21% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/turism.svg);
        width: 141px;
        height: 121px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        right: 0 !important;
      }
    }

    &_beauty {
      right: 45%;
      top: -10% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/beauty.svg);
        width: 141px;
        height: 129px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }
    }

    &_med {
      left: 24% !important;
      top: 27% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/med.svg);
        width: 141px;
        height: 95px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        left: 20% !important;
        top: 24% !important;
      }
    }

    &_nedv {
      left: 18% !important;
      top: 49% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/nedv.svg);
        width: 141px;
        height: 106px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        left: 13% !important;
      }
    }

    &_kurs {
      left: 20% !important;
      top: 72% !important;

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/kurs.svg);
        width: 141px;
        height: 115px;
        top: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        position: absolute;

      }

      @media(min-width: 992px) and (max-width: 1199px) {
        left: 16% !important;
        top: 81% !important;
      }
    }

    &_kafe_ru {
      &::before {
background-image: url(/img/lc2021/img/kurs_ru.svg) !important;
      }
    }

    &_auto_ru {
      &::before {
background-image: url(/img/lc2021/img/auto_ru.svg) !important;
      }
    }

    &_learn_ru {
      &::before {
background-image: url(/img/lc2021/img/learn_ru.svg) !important;
      }
    }

    &_turism_ru {
      &::before {
background-image: url(/img/lc2021/img/turism_ru.svg) !important;
      }
    }

    &_beauty_ru {
      &::before {
background-image: url(/img/lc2021/img/beauty_ru.svg) !important;
      }
    }

    &_med_ru {
      &::before {
background-image: url(/img/lc2021/img/med_ru.svg) !important;
      }
    }

    &_nedv_ru {
      &::before {
background-image: url(/img/lc2021/img/nedv_ru.svg) !important;
      }
    }

    &_kurs_ru {
      &::before {
  background-image: url(/img/lc2021/img/kurs_ru.svg) !important;
      }
    }

  }

  &__dialog {

    &_1 {

      left: -6% !important;
      top: 0px !important;


      @media(min-width: 992px) and (max-width: 1199px) {
        left: -1% !important;
        top: -28px !important;
      }

      @media(min-width: 1200px) and (max-width: 1250px) {
        left: -1% !important;

      }

      @media(min-width: 1251px) and (max-width: 1300px) {
        left: -4% !important;
      }

      &::before {
        content: '';
        background-image: url(/img/lc2021/img/d1.svg);
        width: 444px;
        height: 198px;

        background-size: contain;
        background-position: center;
        position: absolute;
        background-repeat: no-repeat;


        @media(min-width: 992px) and (max-width: 1199px) {
          width: 395px;
          height: 176px;

        }

        @media(min-width: 1200px) and (max-width: 1250px) {
          width: 389px;
          height: 173px;

        }

        @media(min-width: 1251px) and (max-width: 1300px) {
          width: 423px;
        }


      }
    }

    &_2 {

      right: 34% !important;
      top: -7% !important;
      left: auto !important;

      @media(min-width: 992px) and (max-width: 1199px) {
        right: 33.5% !important;
        top: -5% !important;
      }


      &::before {
        content: '';
        background-image: url(/img/lc2021/img/d2.svg);
        width: 404px;
        height: 201px;

        background-size: contain;
        background-position: center;
        position: absolute;
        background-repeat: no-repeat;

        @media(min-width: 992px) and (max-width: 1199px) {
          width: 330px;
          height: 164px;

        }

        @media(min-width: 1200px) and (max-width: 1250px) {
          width: 370px;
          height: 184px;

        }

      }
    }

    &_3 {


      left: -7% !important;
      top: 96% !important;

      @media(min-width: 992px) and (max-width: 1199px) {
        left: 32% !important;
        top: 96% !important;
        left: auto;
      }

      @media(min-width: 1200px) and (max-width: 1250px) {
        left: -2.5% !important;
      }

      @media(min-width: 1251px) and (max-width: 1300px) {
        left: -4% !important;
      }


      &::before {
        content: '';
        background-image: url(/img/lc2021/img/d3.svg);
        width: 387px;
        height: 175px;

        background-size: contain;
        background-position: center;
        position: absolute;
        background-repeat: no-repeat;

        @media(min-width: 1200px) and (max-width: 1250px) {
          width: 359px;
          height: 163px;

        }

        @media(min-width: 1251px) and (max-width: 1300px) {
          width: 359px;
          height: 163px;

        }
      }
    }

    &_1_ru::before{

        background-image: url(/img/lc2021/img/d1_ru.svg);
    }
    &_2_ru::before{

        background-image: url(/img/lc2021/img/d2_ru.svg);
    }
    &_3_ru::before{

        background-image: url(/img/lc2021/img/d3_ru.svg);
    }
  }

}
