.form-auth {

  &__forget {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    max-width: 100vw;
  }

  &__button {

    padding: 10px 50px;
    font-size: 20px;
    outline: none !important;
    border: none;

  }

  &__btn-sign-in {

    @media (min-width: 870px) and (max-width: 900px) {
      padding: 10px 30px;
    }
  }

  .container-form {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      height: 2000px;
      width: 2000px;
      top: -10%;
      right: 48%;
      transform: translateY(-50%);
      /*  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);*/
      background: #e05b29;
      transition: 1.6s ease-in-out;
      border-radius: 50%;
      z-index: 6;
    }
  }

  .content-p {
    font-size: 1.2em !important;
  }

  .forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;

    &__actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 480px;
    }

    &__forget {
      @media (max-width: 325px) {
        font-size: .9em;
      }

      @media (min-width: 901px) {
        font-size: 1.1em;
      }
    }
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 1s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  form.sign-up-form {
    opacity: 0;
    z-index: 1;
  }

  form.sign-in-form {
    z-index: 2;
  }

  .title {
    font-size: 2.2rem;
    color: #444;
    margin-bottom: 10px;
  }

  .input-field {
    max-width: 480px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;

    &__oferta {
      background: transparent;
      display: block;
      font-size: .8em;
      margin-bottom: 0
    }
  }

  .input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
  }

  .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
  }


  .input-field + .input-field__hint {
    margin-top: -10px;
  }

  .input-field__hint {
    color: #aaa
  }
  .input-field__error {
    color: $primary_color;
  }

  .input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
  }

  .social-text {
    padding: 0.7rem 0;
    font-size: 1rem;
  }

  .social-media {
    display: flex;
    justify-content: center;
  }

  .social-icon {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #333;
    border-radius: 50%;
    border: 1px solid #333;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
  }

  .social-icon:hover {
    color: #4481eb;
    border-color: #4481eb;
  }

  .btn {
    width: 150px;
    background-color: #5995fd;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 49px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    transition: 0.5s;
  }

  .btn:hover {
    background-color: #4d84e2;
  }

  .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 375px) {
      grid-template-rows: 1fr 1fr 1fr !important;
    }
  }


  .image {
    width: 60%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
    margin-top: 4em;

    &__left {
      @include media-breakpoint-down("md") {
        margin-top: 1em;
      }
    }
  }

  .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
  }

  .left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;

    @media (min-width: 1900px) {
      padding: 3rem 37% 2rem 12%;
    }
  }

  .right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
  }


  .right-panel .content {
    @media (min-width: 871px) {
      align-self: stretch;
    }
  }

  .panel .content {
    color: #fff;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
  }

  .panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
  }

  .btn.transparent {
    margin: 0;
    background: none;
    border: 2px solid #fff;
    width: 130px;
    height: 41px;
    font-weight: 600;
    font-size: 0.8rem;
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateX(800px);
  }

  /* ANIMATION */

  .container-form.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
  }

  .container-form.sign-up-mode .left-panel .image,
  .container-form.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
  }

  .container-form.sign-up-mode .signin-signup {
    left: 25%;
  }

  .container-form.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
  }

  .container-form.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
  }

  .container-form.sign-up-mode .right-panel .image,
  .container-form.sign-up-mode .right-panel .content {
    transform: translateX(0%);
  }

  .container-form.sign-up-mode .left-panel {
    pointer-events: none;
  }

  .container-form.sign-up-mode .right-panel {
    pointer-events: all;
  }

  @media (orientation: portrait) and (max-width: 450px){
    .container-form {
      min-height: 700px !important;
    }
  }

  @media (max-width: 600px) {
    .container-form {
      min-height: 750px;
    }
  }

  @media (max-width: 600px) {
    .container-form {
      min-height: 750px;
    }
  }
  @media (max-width: 870px) {
    .container-form {
      min-height: 900px;
      height: 100vh;
    }
    .signin-signup {
      width: 100%;
      top: 95%;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
    }

    .signin-signup,
    .container-form.sign-up-mode .signin-signup {
      left: 50%;
    }

    .panels-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;
    }

    .panel {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 2.5rem 8%;
      grid-column: 1 / 2;
    }

    .right-panel {
      grid-row: 3 / 4;
    }

    .left-panel {
      grid-row: 1 / 2;
    }

    .image {
      width: 200px;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.6s;
    }

    .panel .content {
      padding-right: 15%;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.8s;
    }

    .panel h3 {
      font-size: 1.2rem;
    }

    .panel p {
      font-size: 0.7rem;
      padding: 0.5rem 0;
    }

    .btn.transparent {
      width: 110px;
      height: 35px;
      font-size: 0.7rem;
    }

    .container-form:before {
      width: 1500px;
      height: 1500px;
      transform: translateX(-50%);
      left: 30%;
      bottom: 63%;
      right: initial;
      top: initial;
      transition: 2s ease-in-out;
    }

    .container-form.sign-up-mode:before {
      transform: translate(-50%, 100%);
      bottom: 35%;
      right: initial;
    }

    .container-form.sign-up-mode .left-panel .image,
    .container-form.sign-up-mode .left-panel .content {
      transform: translateY(-300px);
    }

    .container-form.sign-up-mode .right-panel .image,
    .container-form.sign-up-mode .right-panel .content {
      transform: translateY(0px);
    }

    .right-panel .image,
    .right-panel .content {
      transform: translateY(300px);
    }

    .container-form.sign-up-mode .signin-signup {
      top: 5%;
      transform: translate(-50%, 0);
    }
  }

  @media (min-width: 871px) and (max-width: 1000px) {
    .container-form:before {
      right: 36%;
    }
    .image {
      width: 90%;
    }
  }

  @media (max-width: 570px) {
    form {
      padding: 0 1.5rem;
      font-size: .7em;
    }

    .image {
      display: none;
    }
    .panel .content {
      padding: 0.5rem 1rem;
    }
    .container-form {
      padding: 1.5rem;
    }

    .container-form:before {
      bottom: 63%;
      left: 50%;
    }

    .container-form.sign-up-mode:before {
      bottom: 28%;
      left: 50%;
    }

    .input-field {
      font-size: 1em;
      height: 45px;

      &__oferta {
        margin-bottom: 1em;
      }
    }
    .input-field i {
      font-size: 1em;
      line-height: 45px;
    }

    .input-field input {
      font-size: 1.3em;
    }
  }

  @media (max-width: 375px) {
    .container-form.sign-up-mode:before {
      bottom: 30% !important;
    }

    .panel {
      //align-items: flex-start;
    }
  }
}
