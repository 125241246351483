.features {

    margin-top: 166px;

    &__bold-title {
        margin-top: 3.1em;
        font-size: 30px;
        font-weight: 600;
        line-height: 35px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 18.5px;
            text-align: center;
            margin-top: 30px;
        }

        @include media-breakpoint-only(sm) {
            font-size: 21px;
        }

        @include media-breakpoint-only(md) {
            font-size: 24px;
            line-height: 28px;
        }


    }

    &_two {
        margin-top: -100px;

        @media(min-width: 1920px) {
                margin-top: -171px;
            }

    }

    &__h {
        margin-bottom: 1.05em;

        &::before {

            content: '';
            position: absolute;
            display: block;
            background-image: url(/img/lc2021/img/line.svg);
            width: 41px;
            height: 2836px;
            top: 259px;
            z-index: -1;
            left: calc(50% - 20.5px);
            background-position: center;

            @include media-breakpoint-down(md) {
                display: none;
            }

            @media(min-width: 1920px) {
                height: 3279px;
            }


        }
    }

    &__description {
        font-size: 18px;
        line-height: 27px;
        margin-top: 24px;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 46px;
            line-height: 22px;
            text-align: center;
        }

        @include media-breakpoint-only(md) {
            margin-bottom: 70px;
            font-size: 19px;
            line-height: 26px;
        }

        @include media-breakpoint-only(sm) {
            font-size: 18.5px;
        }
    }

    &__right-text {
        @include media-breakpoint-down(md) {
            max-width: 435px;
            display: block;
            margin: 0 auto;
        }
    }

    &__left-text {
        margin-left: 30px;




        @include media-breakpoint-down(md) {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            max-width: 406px;
        }

        @include media-breakpoint-down(md) {
            max-width: 435px;
            display: block;
            margin: 0 auto;
        }
    }

    &__img {

        &_smith {
            margin-top: -51px;


            @include media-breakpoint-down(sm) {
                margin-top: 90px;
            }

            @include media-breakpoint-only(md) {
                margin-top: 140px;
            }
             @media(min-width: 1920px) {
                margin-top:  -38px;
            }
        }

    }
}
