.forget{

    background-image: url(/img/lc2021/img/line-bg.svg);
    background-size: cover;
    background-position: center;
    height: 642px;

    @include media-breakpoint-down(xs){
            height: 242px;
        }
    @include media-breakpoint-only(sm){
            height: 286px;
        }
    @include media-breakpoint-only(md){
            height: 389px;
        }

    @media(min-width: 1920px) {
           height: 962px;
            background-size: cover;
        }


    &__subtitle{
        display: block;
        font-weight: 900;
        font-size: 62px;
        text-align: center;
        line-height: 71px;
        color: $primary_text;
        padding-top: 3.8em;


        @include media-breakpoint-down(xs){
            font-size: 25px;
            line-height: 29px;
        }
        @include media-breakpoint-only(sm){
            font-size: 30px;
            line-height: 35px;
        }
        @include media-breakpoint-only(md){
            font-size: 50px;
            line-height: 60px;
            padding-top: 2.5em;
        }
        @include media-breakpoint-only(sm){
            padding-top: 3.4em;
        }
        @include media-breakpoint-only(lg) {
            font-size: 55px;
            line-height: 64px;
            padding-top: 4.6em;
        }
        @media(min-width: 1920px) {
           padding-top: 6.6em;
        }

    }
}
