.price {

    background: $primary_color;
    position: relative;
    padding-bottom: 6em;
    margin-top: 10em;
    margin-bottom: 10em;

    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }

    &::before {

        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna_price1.svg);
        width: 100%;
        height: 119px;
        top: -117px;
        z-index: -9;
        background-position: center;


        @include media-breakpoint-down(sm) {
            background-image: url(/img/lc2021/img/mob/opt.svg) !important;
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-only(xs) {
            height: 118px;
            top: -97px !important;
        }

        @include media-breakpoint-only(sm) {
            height: 153px;
            top: -123px !important;
        }

        @media(min-width: 320px) and (max-width: 384px) {
            top: -70px !important;
            height: 79px !important;
        }
        @media(min-width: 1920px) {
            background-size: cover;
        }
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna_price2.svg);
        width: 100%;
        height: 70px;
        bottom: -68px;
        z-index: -9;
        background-position: center;


        @include media-breakpoint-down(sm) {
            background-image: url(/img/lc2021/img/mob/opt2.svg) !important;
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-only(xs) {
            height: 128px;
            bottom: -87px !important;
        }

        @include media-breakpoint-only(sm) {
            height: 173px;
            bottom: -113px !important;
        }

        @media(min-width: 320px) and (max-width: 384px) {
            bottom: -70px !important;
            height: 89px !important;
        }
        @media(min-width: 1920px) {
            background-size: cover;
        }

    }


    &__title {
        color: #fff;
        font-size: 62px;
        line-height: 71px;
        font-weight: 600;
        margin-bottom: 4em;
        margin-top: 1em;

        @media(min-width: 320px) and (max-width:359px) {
            font-size: 29px !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 35px;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 1em;
        }

        @include media-breakpoint-only(md) {
            font-size: 36px;
            line-height: 50px;
        }

        @include media-breakpoint-only(lg) {
            font-size: 55px;
            line-height: 64px;
        }
    }

    &__container {
        padding-left: 35px;
        padding-right: 35px;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    &__cart {
        color: #000;
        background: #f8f8f8;
        border-radius: 33px;
        padding-top: 33px;
        padding-bottom: 40px;
        //        max-width: 293px;
        display: block;
        margin: 0 auto;



        @include media-breakpoint-down(sm) {
            border-radius: 11px;
            padding-top: 21px;
            padding-bottom: 21px;
            margin-bottom: 30px;
        }

        &_message {
            padding-left: 22px;
            padding-right: 22px;
        }

        &_busines {
            border: 3px solid #92CD7C;
            padding-top: 45px;
            padding-bottom: 81px;
            margin-top: 67px;
            background: #fff !important;


            @include media-breakpoint-down(sm) {
                border-radius: 11px;
                padding-top: 11px;
                padding-bottom: 12px;
                margin-top: 0;
                margin-bottom: 0;
                background: #f8f8f8;
                border: none;
            }

            @include media-breakpoint-only(md) {
                margin-top: 0;
                margin-bottom: 0;
                background: #f8f8f8;
                border: none;
                padding-top: 33px;
                padding-bottom: 40px;
            }

        }


        &_prem {
            margin-top: 90px;


            @include media-breakpoint-down(md) {
                margin-top: 0
            }


        }
        &_new {
            margin-top: 90px;


            @include media-breakpoint-down(md) {
                margin-top: 0
            }


        }

        &_econom {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                background: #FFFFFF;
                opacity: 0.05;
                transform: rotate(-15deg);
                width: 75px;
                height: 75px;
                left: -40px;
                bottom: -40px;
            }


        }

        &_test {
            position: relative;
            max-width: 293px;

            &::before {
                content: '';
                background-image: url(/img/lc2021/img/test-1.svg);
                position: absolute;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 373px;
                height: 195px;
//                left: -58px;
//                top: -156px;
                left: -26px;
                top: -193px;


                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            @include media-breakpoint-down(md) {
                padding-left: 22px;
                padding-right: 22px;
            }
        }

        &_test_ru::before {
          background-image: url(/img/lc2021/img/test-1_ru.svg) !important;
        }

        @include media-breakpoint-only(md) {
            margin-bottom: 2em;
        }
    }




    &__name {
        position: relative;
        font-size: 30px;
        font-weight: 600;
        text-align: center;


        &::before {
            content: '';
            position: absolute;
            width: 123px;
            left: 50%;
            margin-left: -61px;
            height: 2px;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                bottom: -5px;
            }
        }


        @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 28px;
        }
    }

    &__name_test {

        &::before {
            background-color: #4FA9F8;
        }
    }

    &__name_start {

        &::before {
            background-color: #2B9B51;
        }
    }

    &__name_econom {

        &::before {
            background-color: #ED6379;
        }
    }

    &__name_busines {

        &::before {
            background-color: #92CD7C;
        }
    }

    &__name_prem {

        &::before {
            background-color: #FADA4A;
        }
    }

    &__name_new {

        &::before {
            background-color: #e61701;
        }
    }

    &__sum {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 46px;
        margin-top: 10px;

        @include media-breakpoint-down(sm) {
            font-size: 27px;
            line-height: 30px;
            margin-top: 17px;
            margin-bottom: 13px;
        }

        &_green {
            color: #92CD7C;
        }
    }

    &__digit {
        font-size: 44px;
        font-weight: 600;
        color: #469A27;

//        @include media-breakpoint-down(sm) {
//            font-size: 22px;
//            line-height: 25.5px;
//        }

    }

    &__param {
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;

        @include media-breakpoint-down(sm) {
//            text-align: center;
            font-size: 16px;
            line-height: 16px;
        }

    }

    &__value {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding-top: 4px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    &__before,
    &__after {
        content: '';
        position: absolute;
        display: block;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__before_1 {
        background: #FFFFFF;
        opacity: 0.05;
        transform: rotate(-15deg);
        width: 75px;
        height: 75px;
        top: 150px;

        @media(max-width: 1400px) {
            left: 50px;
        }

        @media(min-width: 1401px) and (max-width: 1600px) {
            left: 70px;
        }

        @media(min-width: 1601px) {
            left: 200px;
        }
    }

    &__before_2 {
        background-image: url(/img/lc2021/img/options__left2.svg);
        width: 230px;
        height: 681px;
        bottom: 0;
        background-size: 90%;
        background-position: left;

        @media(max-width: 1400px) {
            background-size: 65%;
            background-position: left;
        }

        @media(min-width: 1401px) and (max-width: 1600px) {
            background-size: 85%;
        }

        @media(min-width: 1601px) {
            background-size: 100%;
        }

        @media(min-width: 1921px) {
            background-image: url(/img/lc2021/img/options__left_2_1920.svg);
            left: 100px;
            width: 337px;
            height: 581px;
        }
    }

    &__after_1 {
        background: #FFFFFF;
        opacity: 0.05;
        transform: rotate(-15deg);
        width: 139px;
        height: 139px;
        top: 230px;
        right: 50px;

        @media(max-width: 1400px) {
            right: 50px;
        }

        @media(min-width: 1401px) and (max-width: 1600px) {
            right: 70px;
        }

        @media(min-width: 1601px) {
            right: 100px;
        }
    }

    &__after_2 {
        @media(min-width: 1820px) {
            background-image: url(/img/lc2021/img/price__after_2.svg);
            width: 596px;
            height: 621px;
            bottom: 0;
            right: 0;
            background-position: top;
            bottom: -65px;
        }
    }

    &__dop-info {

        color: #000;
        font-size: 22px;
        font-weight: 500;
        background: #fff;
        display: inline-block;
        padding: 15px;
        border-radius: 15px;

        @media(max-width:375px){
            font-size: 17px;
        }


    }

    &__dop-info_sale {
        text-align: left !important;
        font-weight: 600;
        font-size: 25px;
        margin-top: 2em;

        @media (max-width: 575px) {
            font-size: 15px !important;
        }
    }

    &__info {
        margin-bottom: 13em;

        @media (max-width: 991px) {
            margin-bottom: 3em;
        }

    }

    &__list {
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
        color: #fff;


        li {
            margin-bottom: 30px;

            @media (max-width: 575px) {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px;
            }
        }
    }

    &__list-title {
        font-size: 40px;
        margin-bottom: 40px;
        margin-top: 100px;
        font-weight: 600;

        @media (max-width: 575px) {
            font-size: 22px;
        }
    }

    &__type {
        font-size: 28px;
        text-align: center;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 25px;

        @media(min-width: 991px) and (max-width: 1199px){
            font-size: 27px;
        }
    }

    &__namefilter {
        font-size: 17px;
        font-weight: 600;
    }

    &__economy {
        font-size: 18px;
        color: #469A27;
        font-weight: 600;
        text-align: center;
    }

    &__month {
        font-size: 27px;
        font-weight: 600;
        text-align: center;
        margin-top: 14px;

    }

    &__year-label {
        font-size: 14px;font-weight: 600;
        text-align: center;
        margin-top: 15px;
    }

    &__year {
        text-align: center;font-weight: 600;
        font-size: 20px;
    }

    &__cart{
        @media(max-width: 575px){
             max-width: 293px;
        }
    }
    &__test-info{
        font-size: 17px;
        line-height: 25px;
        font-weight: 600;
        padding-top: 25px;
    }

}
