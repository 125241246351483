.road {
    background: $primary-color;
    color: $primary-text;
    position: relative;
    padding-bottom: 4em;
    margin-top: 20em;


    @media(max-width: 1919px){
        margin-top: 9em;
    }
    @media(max-width: 768px){
        margin-top: 10em;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna_opt1.svg);
        width: 100%;
        height: 314px;
        top: -306px;
        z-index: -9;
        background-position: center;


        @include media-breakpoint-down(sm) {
            background-image: url(/img/lc2021/img/mob/opt.svg) !important;
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-only(xs) {
            height: 118px;
            top: -97px !important;
        }

        @include media-breakpoint-only(sm) {
            height: 153px;
            top: -123px !important;
        }

        @media(min-width: 320px) and (max-width: 384px) {
            top: -70px !important;
            height: 79px !important;
        }
        @media(min-width: 1920px) {
            background-size: cover;
        }
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna__road_2.svg);
        width: 100%;
        height: 160.5px;
        bottom: -157px;
        z-index: -9;
        background-position: center;


        @include media-breakpoint-down(sm) {
            background-image: url(/img/lc2021/img/mob/opt2.svg) !important;
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-only(xs) {
            height: 128px;
            bottom: -87px !important;
        }

        @include media-breakpoint-only(sm) {
            height: 173px;
            bottom: -113px !important;
        }

        @media(min-width: 320px) and (max-width: 384px) {
            bottom: -70px !important;
            height: 89px !important;
        }
        @media(min-width: 1920px) {
            background-size: cover;
        }

    }

    &__container {
        position: relative;
    }

    &__button {
        padding: 19px 30px;
        display: inline-block;
        margin: 0 auto;


        @include media-breakpoint-down(md) {
            margin-bottom: 2em;
            padding: 15px 30px;
        }
    }

    &__title {
        color: $primary-text;
        font-size: 62px;
        text-align: center;
        line-height: 71px;
        font-weight: 600;
        margin-top: 1em;

        @include media-breakpoint-only(xs) {
            margin-top: 0.3em;
        }

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 35px;
            text-align: center;
        }
        @include media-breakpoint-only(lg) {
            font-size: 55px;
            line-height: 64px;
        }
    }

    &__img {
        margin-top: 92px;
        margin-bottom: 41px;

        @include media-breakpoint-only(xs) {
            margin-top: 50px;
        }
    }

    &__way {
        font-size: 24px;
        text-align: center;
        font-weight: 900;
        margin-bottom: 10px;


    }

    &__subtitle {
        font-size: 18px;
        text-align: center;
        margin-bottom: 21px;

        @include media-breakpoint-only(lg) {
            height: 81px;
        }


    }

    &__descr {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        height: 168px;
        margin-bottom: 46px;
        opacity: 0.7;


        @include media-breakpoint-down(md) {
            height: auto;
            margin-bottom: 40px;
            font-size: 16.5px;
        }

        @include media-breakpoint-only(lg) {
            height: 181px;
        }
    }

    &__before,
    &__after {
        content: '';
        position: absolute;
        display: block;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(lg) {
            display: none;
        }

    }

    &__before_1 {

        @media(min-width: 1920px) {
            background: #FFFFFF;
            opacity: 0.05;
            transform: rotate(-15deg);
            width: 322px;
            height: 322px;
            top: 0;
            left: 100px;
        }
    }

    &__before_2 {

        background: #FFFFFF;
        opacity: 0.05;
        transform: rotate(96.47deg);
        width: 35px;
        height: 35px;
        top: 51.5%;
        left: -35px;

        @media(min-width: 1200px) and(max-width:1366px) {
            left: 0;
        }

    }

    &__before_3 {
        background-image: url(/img/lc2021/img/road__before_1.svg);
        width: 122px;
        height: 207px;
        top: 500px;
        left: 0;

        @media(min-width: 1200px) and(max-width:1366px) {
            display: none;
        }

    }

    &__before_4 {

        background: #FFFFFF;
        opacity: 0.05;
        transform: rotate(96.47deg);
        width: 35px;
        height: 35px;
        bottom: 7px;
        left: -135px;

    }

    &__after_1 {

        background: #FFFFFF;
        opacity: 0.05;
        transform: rotate(60deg);
        width: 156px;
        height: 156px;
        top: 217px;
        right: 150px;

        @media(min-width: 1200px) and(max-width:1366px) {
            width: 121px;
            height: 121px;
            top: 186px;
            right: 32px;
        }
    }



&__after_2 {
    background-image: url(/img/lc2021/img/road__after_2.svg);
    width: 352px;
    height: 681px;
    bottom: -200px;
    right: 0;

    @media(min-width: 1200px) and(max-width:1366px) {
            display: none;
        }

}
}
