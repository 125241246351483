.first-screen {
    $xxl_breakpoint: 1400px;

    &__header {
        padding-top: 1.5em;

        //font-size: 5em !important;

        @include media-breakpoint-down(sm) {
            padding-top: 2.35em;
        }

        @media (min-width: $xxl_breakpoint) {
            padding-top: 153px;

            @media (max-width: 1921px) {
//                font-size: 3.3vw;
                padding-top: 8.1vw;
            }
        }

    }

    &__subheader {
        font-weight: 500;
        font-size: 23px;
        line-height: 36px;
        padding-top: 1em;

        @media(min-width: 320px) and (max-width: 359px) {
            //            font-size: 29px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 30px;
        }
        @include media-breakpoint-only(lg){
            font-size: 22px;
        }

        @media (min-width: $xxl_breakpoint) and (max-width: 1921px) {
//            font-size: 1.28vw;
        }
    }

    &__cta-button {
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 2em;

        @media(min-width: 320px) and (max-width: 359px) {
            font-size: 22px !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 23px;
            padding: 20px;
            display: inline-block;

        }
    }

    &__button-container {
        @media(min-width: 320px) and (max-width: 405px) {
            text-align: center !important;
        }
    }

    @media (min-width: $xxl_breakpoint) and (max-width: 1921px) {
        margin-top: 4.1vw;
        font-size: 1.35vw;
    }
}

