.about {

    margin-top: 11em;
//    margin-bottom: 16em;

    @include media-breakpoint-down(sm) {
        margin-top: 3.67em;
//        margin-bottom: 8em;
    }

    &__bold {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        margin-top: 70px;
        margin-bottom: 40px;
        color: #000;

        @media(min-width: 320px) and (max-width:359px) {
            font-size: 23px !important;
        }

        @include media-breakpoint-down(md) {
            margin-top: 1.4em;
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__light {
        font-size: 20px;
        font-weight: 500;
        color: #000;

        @media(min-width: 320px) and (max-width:359px) {
            font-size: 18.9px !important;
        }
    }

    &__description {
        padding-left: 2em;

        @include media-breakpoint-down(md) {
            padding: 0;
        }
        @include media-breakpoint-up(xl) {
            max-width: 670px;
        }
    }

    &__subtitle {
        font-size: 44px;
        font-weight: 600;
        line-height: 51px;
        margin-top: 3em;
        margin-bottom: 2em;

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            line-height: 29px;
            margin-top: 1.8em;
        }

        @include media-breakpoint-only(md) {
            font-size: 36px;
        }

    }

    &__bullets-text {
        font-size: 23px;
        line-height: 34px;
        font-weight: 500;
        margin-top: 40px;

        @media(min-width: 320px) and (max-width:359px) {
            font-size: 18.2px !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 30px;
            margin-top: 24px;
            margin-bottom: 43px;
        }

        @include media-breakpoint-only(lg) {
            font-size: 19px;
            line-height: 29px;
        }
    }

    &__img {
        margin-top: 5em;

        @include media-breakpoint-down(md) {
            margin-top: 2.3em;
        }
    }

    &__h {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}
