.selector {
    height: 70px;
    width: 39px;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    &, & * {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none; 
    }


    &__wrapper {
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;
        transition: .2s;
    }

    &__wrapper:hover &__hint {
        opacity: 1;
        
        transition-delay: 0s;
    }

    &__input {
        -webkit-appearance: none;
        width: 100%;
        height: 4px;
        outline: none;
        border-radius: 3px;
        display: block;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 48px;
            height: 48px;
            cursor: pointer;
            z-index: 3;
            position: relative;
        }
    }

    &__btn {
        height: 17px;
        width: 17px;
        background: #e05b29;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 50%;
        font-weight: 400;
        margin-left: -10px;
    }

    &__hint {
        width: 39px;
        height: 30px;
        position: absolute;
        top: 0;
        background: #e05b29;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        line-height: 35px;
        font-size: 15px;
        font-weight: bold;
        opacity: 0;

        transition: .2s linear;
        transition-delay: 1s;


        &::after {
            content: "";
            border-top: 14px solid #e05b29;
            border-left: 19.5px solid transparent;
            border-right: 19.5px solid transparent;
            position: absolute;
            bottom: -12px;
            left: 0;
        }

        &_visible {
            opacity: 1;
        }
    }

    &__filled {
        width: 50%;
        height: 4px;
        background: #e05b29;
        border-radius: 3px;
        top: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
    }
}
