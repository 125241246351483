.options {

    background: $primary_color;
    color: $primary_text;
    position: relative;
    padding-bottom: 3em;

    &__container {
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna_opt1.svg);
        width: 100%;
        height: 314px;
        top: -309px;
        z-index: -9;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(sm){
            background-image: url(/img/lc2021/img/mob/opt.svg) !important;
        }

         @include media-breakpoint-only(xs){
            height: 118px;
            top: -95px !important;
        }
        @include media-breakpoint-only(sm){
            height: 158px;
            top: -125px !important;
        }

        @media(min-width: 320px) and (max-width: 384px){
            top: -70px !important;
            height: 75px !important;
        }


    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url(/img/lc2021/img/volna_opt2.svg);
        width: 100%;
        height: 175px;
        bottom: -173px;
        z-index: -9;
        background-position: center;
        background-size: cover;


        @include media-breakpoint-down(sm){
            background-image: url(/img/lc2021/img/mob/opt2.svg) !important;
        }

         @include media-breakpoint-only(xs){
            height: 122px;
            bottom: -95px !important;
        }
        @include media-breakpoint-only(sm){
            height: 168px;
            bottom: -125px !important;
        }

        @media(min-width: 320px) and (max-width: 384px){
            bottom: -70px !important;
            height: 95px !important;
        }

    }

    &__bullets-text {
        font-size: 22px;
        font-weight: 500;

        @include media-breakpoint-down(md){
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 30px;
        }
        @include media-breakpoint-only(sm){
            font-size: 18.9px;
            line-height: 28px;
        }
    }

    &__h {
        margin-bottom: 1em;
        margin-top: 0.5em;

        @include media-breakpoint-only(md){
            font-size: 50px;
        }

        @include media-breakpoint-only(lg) {
            font-size: 55px;
            line-height: 64px;
        }
    }

    &__button {
        margin-top: 3.25em;

        @include media-breakpoint-down(md){
            margin-top: 50px;
            font-size: 23px;
            padding: 17px 60px;

        }
    }

    &__before,
    &__after{
        content: '';
        position: absolute;
        display: block;
        background-position: center;
        background-repeat: no-repeat;


         @include media-breakpoint-down(md){
            display: none;
        }
    }


    &__before_1 {
        background-image: url(/img/lc2021/img/options__left1.svg);
        width: 89px;
        height: 89px;
        top: -80px;
        left: 30px;

    }

    &__before_3 {
        background-image: url(/img/lc2021/img/options__left3.svg);
        width: 433px;
        height: 433px;
        bottom: -230px;
        left: -200px;
    }



    &__after_1 {
        background-image: url(/img/lc2021/img/options__right1.svg);
        width: 162px;
        height: 162px;
        top: 40px;
        right: 30px;

    }

    &__after_2 {
        background-image: url(/img/lc2021/img/options__after_2.svg);
        width: 678px;
        height: 1332px;
        background-size: 50%;
        background-position: right;
        right: 0;
        bottom: -630px;

        @media(min-width: 1401px) and (max-width: 1800px) {
                background-size: 72%;
    bottom: -574px;
        }

        @media(min-width: 1600px) {
            bottom: -500px;
            right: 0;

        }

        @media(min-width: 1601px) and (max-width: 1800px) {
            background-size: 84%;
        }
         @media(min-width: 1801px) {
            background-size: contain;
            background-size: contain;
        }

    }






    @media(min-width: 1400px) {
        &__before_1 {
            left: 100px;

        }

        &__after_1 {
            right: 50px;

        }


        &__before_2 {
            background-image: url(/img/lc2021/img/options__left2.svg);
            width: 230px;
            height: 681px;
            top: 173px;
        }
    }

    @media(min-width: 1401px) and (max-width: 1600px) {
        &__before_1 {
            left: 100px;

        }


    }




    @media(min-width: 1601px) and (max-width: 1920px) {
        &__before_1 {
            left: 250px;

        }

    }

    @media(min-width: 1921px) {
        &__before_2 {
            background-image: url(/img/lc2021/img/options__left_2_1920.svg);
            width: 437px;
            height: 681px;
            top: 173px;
        }

        &__before_1 {
            left: 350px;

        }

        &__after_1 {
            right: 100px;

        }
    }



}
