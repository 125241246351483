.click-button {
    margin-top: 14em;
    margin-bottom: 19em;

    @include media-breakpoint-down(md) {
        margin-top: 4em;
        margin-bottom: 8em;
    }

    &__button {
        box-shadow: 0px 22px 32px -18px rgba(224, 91, 41, 0.53);
        padding: 20px 50px;
        display: inline-block;
        margin-top: 48px;
        margin-bottom: 70px;

        &:hover {
            box-shadow: none;
        }

        @include media-breakpoint-down(md) {
            font-size: 23px;
            padding: 20px 40px;
        }
    }

    &__push,
    &__text {
        color: #000;
        font-size: 36px;
        font-weight: 900;
        line-height: 52px;
        position: relative;
        font-family: "Montserrat";

        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
        }
    }

    &__text{
        @include media-breakpoint-only(md) {
            margin-bottom: 6em;
        }
    }
    &__push {
        display: inline-block;

        &::before {
            content: '';
            background-image: url(/img/lc2021/img/arrow.svg);
            width: 113px;
            height: 132px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            right: -136px;
            top: 3px;


            @include media-breakpoint-down(md) {
                background-image: url(/img/lc2021/img/mob/arrow.svg);
                width: 39px;
                height: 44px;
                right: -57px;
                top: 5px;
            }
        }
    }
}
