.header-mob{

    @include media-breakpoint-up(lg){
            display: none;
        }


    &__modal-dialog{
        margin: 0 !important;
    }

    &__modal-content{
        padding: 40px 0;
        border: none !important;
        border-radius: 0 !important;
    }

    .fade{
        background: rgba(224, 91, 41, 0.8);
    }

    &__button-auth{
        background: none;
        border: none;

        &:focus{
            border: none !important;
            outline: none !important;
        }
    }
    &__button-hamb{
        background: none;
        border: none;

        &:focus,
        &:active{
            border: none !important;
            outline: none !important;
        }
    }

    &__nav-link{
        font-weight: 500;
        color: #252525;
        font-size: 25px;
        line-height: 29px;

        &:hover{
            color: $accent-text;
        }

        &_accent {
            color: $accent-text !important;
        }
    }
    &__nav-item{
        margin-right: 85px;
    }
    &__navbar-nav :last-child {
        margin-right: 0;
    }
}
