.footer{
    background: #F8F8F8;
    z-index: -1;
    margin-top: 9em;
    padding-top:5em;
    padding-bottom: 9em;

     @include media-breakpoint-down(sm) {
            background: #fff;
        }

    &__ip{
        font-weight: 500;
        margin-top: 1em;
    }
    &__nav-link{
        font-weight: 600;
        color: #000;
        font-size: 20px;

        &:hover{
            color: $accent-text;
        }

        @include media-breakpoint-only(md) {
            display: inline;
        }
    }
    &__nav-item{
        margin-right: 85px;


        @include media-breakpoint-only(md) {
            display: inline;
        }
        @include media-breakpoint-only(lg) {
            margin-right: 40px;
        }

    }
    &__navbar-nav :last-child {
        margin-right: 0;
    }
    &__soc{
        padding-top: 0.8em;
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }


        &-link {
            text-decoration: none !important;
        }

    }
    &__soc :first-child{
            margin-right: 15px;
            display: inline-block;
        }
    &__soc-link:hover &__soc-img path{
        fill: $primary-color !important;
    }
    &__navbar-nav{
        @include media-breakpoint-only(md) {
            display: block;
        }
    }

    &__navbar{

        @include media-breakpoint-down(md) {
            padding-left: 0 !important;
        }
        @include media-breakpoint-only(md) {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

}
