.h{
    &_1{
        font-size: 62px;
        font-weight: 900;
        line-height: 1.1em;
        @media(min-width: 320px) and (max-width: 359px){
            font-size: 29px !important;
        }
        @include media-breakpoint-down(md){
            font-size: 30px;
            line-height: 35px;
        }
        @include media-breakpoint-only(lg){
            font-size: 50px;
        }
    }
    
    &_2{
        font-size: 84px;
        font-weight: 900;
        line-height: 97px;
        
        @include media-breakpoint-down(md){
            font-size: 30px;
            line-height: 35px;
        }
        @include media-breakpoint-only(md){
            font-size: 50px;
            line-height: 60px;
        }
        @include media-breakpoint-only(lg) {
            font-size: 65px;
            line-height: 74px;
        }
    }
}