.lirika {
    &__subtitle {
        font-size: 44px;
        font-weight: 600;
        line-height: 51px;
        
        @media (max-width: 575px){
                font-size: 21px;
    line-height: 34px;
        }
    }
    
    &__title{
        text-align: center;
        margin: 0 auto;
    font-size: 30px;
        display: inline-block;
    font-weight: 600;
    line-height: 35px;
        margin-bottom: 1em;
        
        @media (min-width: 421px) and (max-width: 575px){
                margin-bottom: 2em;
        }
    }
    &__smile{
        width: 80px;
        margin-left: 20px;
        margin-top: -20px;
        
        @media (max-width: 424px){
                margin-left: 0;
            margin-bottom: 1em;
        }
    }
    p{
            font-size: 22px;
    font-weight: 500;
    }
}