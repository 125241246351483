.header{
    
    &__login{
        color: $accent_text;
        font-weight: 600;
        font-size: 17px;
        margin-top: 13px;
        display: block;
        
        &:hover{
            text-decoration: none;
            color: $accent_text;
            
        }
    }
    
    @include media-breakpoint-down(md){
            display: none;
        }
}