.doit {
    margin-top: 17em;

    
    @include media-breakpoint-only(xs){
            margin-top: 7.5em;
        }
    @media(min-width: 384px) and (max-width: 767px){
        margin-top: 10em;
    }
    @include media-breakpoint-only(sm){
        margin-top: 12em;
    }
    
    &__subtitle {
        font-size: 44px;
        font-weight: 600;
        line-height: 51px;
        margin-bottom: 2em;

        @include media-breakpoint-down(md) {
            font-size: 25px;
            line-height: 29px;
        }

        @include media-breakpoint-only(md) {
            font-size: 36px;
        }

    }

    &__type {
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;

        @include media-breakpoint-only(xs) {
            margin-top: 30px;
            font-size: 18px;
            line-height: 22px;
        }

        @include media-breakpoint-only(sm) {
            font-size: 20px;
        }

        @include media-breakpoint-only(lg) {
            font-size: 20px;
        }
    }

    &__text {
        font-size: 17.7px;
        line-height: 27px;
        margin-top: 18px;

        @media(min-width: 320px) and (max-width: 340px) {
            font-size: 16px !important;
        }

        @include media-breakpoint-only(xs) {
            font-size: 17px;
            line-height: 23px;
        }
    }

    &__info {
        padding-left: 20px;
        font-weight: 500;

        @include media-breakpoint-only(xs) {
            padding-left: 0;
        }

        @include media-breakpoint-between(sm, md) {
            padding-left: 10px;
        }

        @include media-breakpoint-only(lg) {
            padding-left: 0;
        }

        @include media-breakpoint-only(xl) {
            padding-left: 14px;
        }



    }

    &__elem {
        margin-bottom: 75px;

        @include media-breakpoint-only(xs) {
            text-align: center;
            margin-bottom: 35px;
        }

        @include media-breakpoint-between(sm, md) {
            text-align: left;
        }


        img {
            
            @include media-breakpoint-only(lg){
                width: 60px;
            }
            @include media-breakpoint-only(xl){
                width: 72px;
            }
        }
    }

    &__button-container {
        text-align: center;
    }

    &__button {
        font-weight: 600;
        padding: 0.75em 2.3em;
        box-shadow: 0px 22px 32px -18px rgba(224, 91, 41, 0.53);
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 1em;

        &:hover {
            box-shadow: none;
        }

        @media(min-width: 320px) and (max-width: 359px) {
            font-size: 22px !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 23px;
            padding: 20px 50px;
            display: inline-block;
            margin-top: 2em;

        }
    }
}