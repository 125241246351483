@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{str-replace(str-replace(#{$navbar-dark-color}, "(", "%28"), ")", "%29")}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{str-replace(str-replace(#{$navbar-light-color}, "(", "%28"), ")", "%29")}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/print";

@import "~bootstrap/scss/utilities/align";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/float";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/text";

@import "colors";

//@font-face {
//    font-family: "GTEestiProDisplay";
//    src: url("/fonts/GTEestiProDisplay-Bold.ttf");
//    font-style: normal;
//    font-weight: 900;
//}
@font-face {
    font-family: 'GTEestiProDisplay';
    src: local('GT Eesti Pro Display Bold'), local('GT-Eesti-Pro-Display-Bold'),
        url('/fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
        url('/fonts/GTEestiProDisplay-Bold.woff') format('woff'),
        url('/fonts/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GTEestiProDisplay";
    src: url("/fonts/GTEestiProDisplay-Light.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "GTEestiProDisplay";
    src: url("/fonts/GTEestiProDisplay-Medium.ttf");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "GTEestiProDisplay";
    src: url("/fonts/GTEestiProDisplay-Regular.ttf");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'GTEestiProDisplay';
    src: local('GT Eesti Pro Display Regular'), local('GT-Eesti-Pro-Display-Regular'),
        url('/fonts/GTEestiProDisplay-Regular.woff2') format('woff2'),
        url('/fonts/GTEestiProDisplay-Regular.woff') format('woff'),
        url('/fonts/GTEestiProDisplay-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro';
    src: local('GT Eesti Pro Display Bold'), local('GT-Eesti-Pro-Display-Bold'),
        url('/fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
        url('/fonts/GTEestiProDisplay-Bold.woff') format('woff'),
        url('/fonts/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Bold.ttf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}


body {
    color: $base_text;
    font-family: "GTEestiProDisplay";
    font-weight: 400;
//    padding-top: 30px;
}

@import "button";
@import "header-wrapper";
@import "header";
@import "header-mob";
@import "h";
@import "text";
@import "about";
@import "first-screen";
@import "options";
@import "doit";
@import "teh-info";
@import "forget";
@import "features";
@import "price";
@import "shiva";
@import "lirika";
@import "shiva-mob";
@import "click-button";
@import "road";
@import "footer";
@import "nowrap";
@import "form-auth";
@import "selector";


