.shiva-mob {
    @include media-breakpoint-up(lg) {
        display: none;
    }


    &__title {
        font-size: 62px;
        text-align: center;
        font-weight: 600;
        line-height: 71px;
        margin-top: 3.7em;

        @include media-breakpoint-only(sm) {
            margin-top: 5.7em;
        }

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 35px;
        }
         @include media-breakpoint-down(sm) {
            margin-top: 1.7em;
        }
    }

    &__img {
        margin: 2em auto;

        @include media-breakpoint-only(sm) {
            margin: 3em auto;
        }
    }
}
